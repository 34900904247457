import React from "react"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import theme from "../../styles/theme"
import { motion } from "framer-motion"
import Container from "@components/layout/container"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import GradientText from "@components/common/gradient-text"
import Span from "@components/elements/span"
import { gradientTextAnim } from "@animations/gradient-text-anim"
import { MotionFlex, MotionHeading } from "@components/elements/motion"
import { TextContainer } from "@components/pages/home/common"
import PropTypes from "prop-types"
import { ServiceLibraryAnimation } from "@components/animated/service-library"

export const HomeSectionData = ({ alt, hideText, klips }) => {
  const data = useStaticQuery(
    graphql`
      query DataAllOverQuery {
        logo: directusFiles(
          directus_id: { eq: "44795620-a751-4fdf-817c-6bcb6e43c44d" }
        ) {
          cdn
          id
          placeholder
          title
        }
        klipsLogo: directusFiles(
          directus_id: { eq: "d146fe6e-5ad1-4ff9-b28c-027fa1f34176" }
        ) {
          cdn
          id
          placeholder
          title
        }
      }
    `
  )

  return (
    <Div margin="12rem 0 0">
      <Container>
        <Flex
          maxWidth="100%"
          overflow="hidden"
          padding={klips ? "8rem 0 0" : "16rem 0 0 0"}
          gap="2rem"
          alignItems="center"
        >
          <MotionFlex
            gap="2rem"
            maxWidth="100%"
            alignItems="center"
            justifyContent="center"
            margin="0 0 4rem"
            position="relative"
          >
            <Flex
              margin="0"
              overflow="hidden"
              maxWidth="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Div
                position="absolute"
                padding="2.5rem"
                background="white"
                boxShadow={theme.shadow.default}
                borderRadius="3rem"
                zIndex={10}
              >
                {klips ? (
                  <Image
                    height={130}
                    width={132}
                    file={data.klipsLogo}
                    loading="lazy"
                  />
                ) : (
                  <Image height={130} file={data.logo} loading="lazy" />
                )}
              </Div>
              <ServiceLibraryAnimation alt={alt} />
            </Flex>
            {!hideText && (
              <TextContainer
                margin="2rem 0 0"
                gap="1rem"
                alignItems="flex-start"
              >
                <Span fontWeight="600" fontSize="1.3rem">
                  Connect all your data
                </Span>
                <MotionHeading fontSize="4rem" margin="0 0 1rem" as="h2">
                  All your data works
                  <br />
                  <GradientText
                    variants={gradientTextAnim}
                    initial={"initial"}
                    whileInView="active"
                    viewport={{ once: false, margin: "0px 0px -10% 0px" }}
                    as={motion.span}
                    style={{ display: "inline" }}
                  >
                    beautifully in Klipfolio.
                  </GradientText>
                </MotionHeading>
                <Paragraph lineHeight="1.7" margin="0 0 1rem">
                  {`From spreadsheets, SQL, REST, pre-built metrics and more, there
                are over a hundred ways to connect your team's data to
                PowerMetrics. And once connected, you can easily clean up messy,
                incomplete or complicated data in just a few clicks.`}
                </Paragraph>
                <Button
                  arrow
                  background="#EDF3FD"
                  color="#4B57C5"
                  href="/powermetrics/connect-your-data"
                  notice={false}
                >
                  Learn more
                </Button>
              </TextContainer>
            )}
          </MotionFlex>
        </Flex>
      </Container>
    </Div>
  )
}

HomeSectionData.propTypes = {
  alt: PropTypes.bool,
  hideText: PropTypes.bool,
  klips: PropTypes.bool,
}
