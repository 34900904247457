import Flex from "@components/elements/flex"
import styled from "styled-components"
import media from "../../../styles/media"

export const TextContainer = styled(Flex)`
  width: 900px;
  ${media.md`
    width: auto;
  `}
`
