import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import styled, { useTheme } from "styled-components"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import GradientText from "@components/common/gradient-text"
import Flex from "@components/elements/flex"
import List from "@components/elements/list"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import { HomeSectionData } from "@sections/home/data-all-over"
import media from "@styles/media"
import Container from "@components/layout/container"
import { ServicesBlock } from "@components/common/services-block"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const Testimony = ({ name, role, testimony, image }) => {
  const { color } = useTheme()

  return (
    <Div>
      <Paragraph color={color.blue600} margin="0 0 2rem" fontWeight={600}>
        {testimony}
      </Paragraph>
      <Flex alignItems="center" flexFlow="row" gap="1rem">
        <Image width={80} height={80} file={image} />
        <Flex>
          <Paragraph fontWeight={700} color={color.blue600}>
            {name}
          </Paragraph>
          <Paragraph fontSize="1rem" color={color.blue600}>
            {role}
          </Paragraph>
        </Flex>
      </Flex>
    </Div>
  )
}

Testimony.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  testimony: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const ShareSquare = ({ name, text, image }) => {
  const { color } = useTheme()

  return (
    <Flex gap="1rem">
      <Image file={image} />
      <Heading margin="1rem 0 0" as="h4" color={color.blue600}>
        {name}
      </Heading>
      <Paragraph>{text}</Paragraph>
    </Flex>
  )
}

ShareSquare.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const SharingContainer = styled(Div)`
  ${media.md`
    padding: 0;
    max-width: unset;
  `}
`

const KlipsPage = ({ data }) => {
  const { color } = useTheme()
  const images = processImages(data.images.edges)
  const { gradient } = useGradient(true)
  return (
    <Layout
      title="Klips - Custom dashboards and reports for builders"
      description={`Build custom dashboards and reports for your team. Customize and automate dashboards and reports to measure, track, and share accurate and up to date insights.`}
      fullWidth
      login
    >
      <Div position="relative" overflow="hidden">
        <HighGradient top="48rem" src={gradient.cdn} loading="lazy" />
        <Flex
          margin="4rem auto"
          alignItems="center"
          gap="2rem"
          textContainer
          center
          position="relative"
        >
          <KlipsLogo height="60" />
          <Heading fontSize="4rem" color={color.blue600}>
            Custom dashboards for
            <br />
            <Span color={color.blue400}>you and your team</Span>
          </Heading>
          <Paragraph fontWeight={600} lineHeight={1.5} color={color.blue600}>
            Klips lets you build real-time, pixel-perfect data visualizations
            <br />
            and distribute powerful custom dashboards and reports.
          </Paragraph>
          <Button.Klips force />
        </Flex>

        <Div container>
          <Image file={images["7f4669cc-a755-49b8-b8f9-a5c5a67c4f8c"]} />
        </Div>
      </Div>

      <HomeSectionData hideText klips />

      <Flex gap="6rem" margin="0 0 8rem">
        <Div margin="0 auto 10rem" textContainer>
          <Heading
            fontSize="4rem"
            margin="0 0 2rem"
            as="h2"
            color={color.blue600}
          >
            Connect your data
            <br />
            <GradientText gradient="linear-gradient(90deg, #18AED8 0%, #1278A1 100%)">
              all in one place
            </GradientText>
          </Heading>
          <Flex gap="1rem">
            <Paragraph color={color.blue600}>
              Bring all of your data together to build custom dashboards and
              reports—no data warehouse required.
            </Paragraph>
            <Paragraph color={color.blue600}>
              Real-time, raw data refresh is streamed into your visualizations
              so you always have insights at your fingertips.
            </Paragraph>
            <List margin="0 0 0 2rem" color={color.blue600}>
              <li>
                Klips integrates with 130+ data services. If you don&apos;t see
                what you&apos;re looking for, connect using our REST/URL option
              </li>
              <li>
                Pull in data that&apos;s stored on premise or in the cloud,
                upload spreadsheets, or write custom queries
              </li>
              <li>
                Use Excel-like formulas and functions to transform your data,
                perform calculations, manipulate dates or text, and add logic
                and statistics
              </li>
            </List>
            <Paragraph color={color.blue600}>
              Security, privacy, and access are our top priority. Read
              about&nbsp;
              <Anchor
                color={color.blue400}
                link="https://www.klipfolio.com/legal/security"
              >
                the steps we take
              </Anchor>
              &nbsp;to ensure your data is always safe and secure.
            </Paragraph>
          </Flex>
        </Div>

        <Heading
          as="h2"
          fontSize="4rem"
          center
          color={color.blue600}
          margin="6rem auto"
        >
          Complex data?
          <br />
          <Span color={color.blue400}>Klips can visualize that</Span>
        </Heading>

        <Div textContainer>
          <Heading
            as="h2"
            fontSize="4rem"
            color={color.blue600}
            margin="0 0 2rem"
          >
            Klip <Span color={color.blue400}>Editor</Span>
          </Heading>
          <Paragraph color={color.blue600} margin="0 0 1rem">
            Consolidate and streamline complex data with our extensive library
            of formulas and functions, filtering, grouping, aggregating and
            sorting.
          </Paragraph>
          <Paragraph color={color.blue600} margin="0 0 2rem">
            Dynamically assign values based on user input and properties and
            filter using those variables in Klips and dashboards.
          </Paragraph>
          <Image file={images["810ee4be-c5dd-4d84-b3a5-13fdbe81f523"]} />
        </Div>

        <Div textContainer>
          <Heading
            as="h2"
            fontSize="4rem"
            color={color.blue600}
            margin="0 0 2rem"
          >
            Extensive
            <br />
            <Span color={color.blue400}>Chart Library</Span>
          </Heading>
          <Paragraph color={color.blue600} margin="0 0 2rem">
            With 30+ chart types to choose from, there&apos;s a visualization
            for every need. From pie, bar, and line charts to more comprehensive
            charts like gauges, pictographs, maps, or funnels.
          </Paragraph>
          <Image file={images["ebae1521-5b20-4ec6-afb2-ac64ff81f306"]} />
        </Div>

        <Div textContainer>
          <Heading
            as="h2"
            fontSize="4rem"
            color={color.blue600}
            margin="0 0 2rem"
          >
            HTML, CSS & JS
            <br />
            <Span color={color.blue400}>Customization</Span>
          </Heading>
          <Paragraph color={color.blue600} margin="0 0 2rem">
            Control the look and feel of your dashboards and Klips with custom
            themes. Modify display properties in the Klip Editor or dig in and
            build powerful, one-of-a-kind visualizations using the HTML template
            component, along with JavaScript and inline CSS.
          </Paragraph>
          <Image file={images["fb926593-84a9-4a59-9666-c6fa633e3035"]} />
        </Div>
      </Flex>

      <ServicesBlock klips text={color.blue600} />

      <Div
        position="relative"
        style={{ overflowX: "clip", overflowY: "visible" }}
      >
        <Div container>
          <Image file={images["8841f346-2a38-4169-a671-b4b68ceeb581"]} />
        </Div>
        <HighGradient
          top="30rem"
          style={{ zIndex: -1 }}
          src={gradient.cdn}
          loading="lazy"
        />
      </Div>

      <Flex
        margin="2rem auto 8rem"
        textContainer
        alignItems="flex-start"
        gap="1rem"
      >
        <Heading as="h2" fontSize="4rem" color={color.blue600}>
          Live dashboard&nbsp;
          <Span color={color.blue400}>examples</Span>
        </Heading>
        <Paragraph margin="0 0 1rem">
          Get inspired by&nbsp;
          <Anchor
            color={color.blue400}
            link="https://www.klipfolio.com/live-dashboards"
          >
            90+ interactive dashboard examples
          </Anchor>
          &nbsp;that you can view by department or service.
        </Paragraph>
        <Button
          href="https://www.klipfolio.com/live-dashboards"
          notice={false}
          arrow
          background={color.blue600}
        >
          View Live Dashboards
        </Button>
      </Flex>

      <SharingContainer container>
        <Image file={images["64be2cba-5b39-48cb-a2a3-9612d337bb75"]} />
      </SharingContainer>

      <Flex
        zIndex={10}
        margin="-10rem auto 8rem"
        marginMd="-8rem auto 8rem"
        marginSm="-6rem auto 6rem"
        textContainer
        gap="1rem"
      >
        <Heading fontSize="4rem" as="h2" color={color.blue600}>
          Sharing and distribution
          <br />
          <Span color={color.blue400}>for decision-making with confidence</Span>
        </Heading>
        <Paragraph color={color.blue600} margin="1rem 0 2rem">
          Creating dashboards and reports for clients? Learn more about&nbsp;
          <Anchor
            color={color.blue400}
            link="https://www.klipfolio.com/klips/partners"
          >
            client management
          </Anchor>
          .
        </Paragraph>
        <Grid gap="2rem" columns="1fr 1fr" columnsSm="1fr">
          <ShareSquare
            name="Automated Reporting"
            text={`With automated reporting, sharing insights is easy. Render your dashboards and reports as PDFs or images then share them on a
              one-time basis or schedule an automated email at a frequency you
              define.`}
            image={images["d49467d8-e4fa-4398-8a3e-685ecbe55329"]}
          />
          <ShareSquare
            name="Published Links"
            text={`Published links let you share your dashboards with anyone inside or outside of your organization using a public or password
              protected link.`}
            image={images["83576fd1-d8c7-4b00-8662-1d701096ab6a"]}
          />
          <ShareSquare
            name="TV Dashboards"
            text={`Display your dashboards in full-screen dark mode on TVs in
            meeting rooms, reception areas, or around your office.`}
            image={images["d128c156-78e0-4701-99f0-696b618e8ac2"]}
          />
          <ShareSquare
            name="User and Group Sharing"
            text={`Choose the level of control you need to manage access to your data. Share with individuals or groups, grant view or edit rights to dashboards, Klips, and data sources or assign custom roles to further define permissions.`}
            image={images["4a6a6bf9-5307-4bc1-bef9-5bf6e723c02f"]}
          />
        </Grid>
      </Flex>

      <Div
        position="relative"
        style={{ overflowX: "clip", overflowY: "visible" }}
      >
        <Div container>
          <Image file={images["1a6a9371-7cc4-476a-88c9-4bbbf29f8001"]} />
        </Div>
        <HighGradient
          top="30rem"
          style={{ zIndex: -1 }}
          src={gradient.cdn}
          loading="lazy"
          hideOnMd
        />
      </Div>

      <Flex margin="4rem auto 12rem" textContainer gap="1rem">
        <Heading
          fontSize="4rem"
          margin="0 0 1rem"
          as="h2"
          color={color.blue600}
          maxWidth="20ch"
        >
          World class support for{" "}
          <Span color={color.blue400}>you and your team</Span>
        </Heading>
        <Paragraph margin="0 0 1rem" color={color.blue600}>
          Our mission is to help you succeed with data.
        </Paragraph>
        <Paragraph fontWeight={700} color={color.blue600}>
          Have a technical question?
        </Paragraph>
        <Paragraph color={color.blue600}>
          Our support team is available via email or our&nbsp;
          <Anchor
            link="https://support.klipfolio.com/hc/en-us"
            color={color.blue400}
          >
            help center
          </Anchor>
          .
        </Paragraph>
        <Paragraph margin="0 0 1rem" color={color.blue600}>
          For self-serve learners, access our extensive library of&nbsp;
          <Anchor
            color={color.blue400}
            link="https://www.youtube.com/user/klipfolio/playlists"
          >
            video tutorials
          </Anchor>
          ,&nbsp;
          <Anchor
            color={color.blue400}
            link="https://apidocs.klipfolio.com/reference/getting-started"
          >
            API documentation and guides
          </Anchor>
          , and&nbsp;
          <Anchor
            color={color.blue400}
            link="https://support.klipfolio.com/hc/en-us/categories/360001248554-Klips"
          >
            help articles
          </Anchor>
          .
        </Paragraph>
        <Paragraph color={color.blue600} fontWeight={700}>
          Need priority support?
        </Paragraph>
        <Paragraph color={color.blue600} margin="0 0 1rem">
          If you need immediate assistance or help with complex technical
          questions, our Priority Support Power-Up ensures your request jumps to
          the top of our support queue.
        </Paragraph>
        <Paragraph color={color.blue600} fontWeight={700}>
          Looking for training on how to build your own dashboards?
        </Paragraph>
        <Paragraph color={color.blue600} margin="0 0 1rem">
          Our Data Hero team will get you up and running. Spend 1-on-1 time with
          a Klipfolio expert and get answers to your dashboard and design
          questions. Data Hero users get priority ticket handling.&nbsp;
          <Anchor
            color={color.blue400}
            link="https://www.klipfolio.com/solutions/data-hero-services"
          >
            Learn more about Data Hero.
          </Anchor>
        </Paragraph>
        <Paragraph color={color.blue600} fontWeight={700}>
          Need a hand building custom dashboards or don&apos;t have time?
        </Paragraph>
        <Paragraph color={color.blue600}>
          The Klipfolio certified partners in our&nbsp;
          <Anchor
            color={color.blue400}
            link="https://www.klipfolio.com/partners/directory"
          >
            Partner Directory
          </Anchor>
          &nbsp;are here to build your custom dashboards and reports. Klipfolio
          partners have helped thousands of businesses connect their data and
          deliver insights where and when it&apos;s needed most. If you can
          dream it, our partners can help you build it.
        </Paragraph>
      </Flex>

      <Container>
        <Image file={images["fa68df7c-d22e-4018-96b9-46d2b6b66e22"]} />
      </Container>

      <Flex textContainer gap="4rem" margin="8rem auto 6rem">
        <Heading
          as="h2"
          margin="6rem auto 0"
          fontSize="4rem"
          color={color.blue600}
        >
          See what our customers are saying&nbsp;
          <GradientText variant="klips">about Klips</GradientText>
        </Heading>
        <Testimony
          name="Andrew W."
          role="Director at IT MOOTI"
          image={images["05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"]}
          testimony={`I build dashboards for clients across many industries and it is by far the best tool I've come across for building KPI reports that are used daily and motivate teams to make progress on business goals.`}
        />
        <Testimony
          name="Carolyn S."
          role="People Insights Manager at formstack.com"
          image={images["21dba570-e178-4e0b-ae60-7fb373b554e8"]}
          testimony={`I like how much you can customize the look and feel of your dashboard. Klipfolio connects all of our disparate systems within our HR department. Seeing survey, HRIS, and ATS data in one
            dashboard is really cool.`}
        />
        <Testimony
          name="Sebastián P."
          role="CPO at E-Planning.net"
          image={images["c411faf3-a2bb-4dcd-909b-cc6e24d68a54"]}
          testimony={`We upload daily data about our business and it's really helpful to get insights and monitor revenue in real-time.`}
        />
      </Flex>

      <KlipsEOPCta force />
    </Layout>
  )
}

KlipsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KlipsPage

export const pageQuery = graphql`
  query KlipsPageQuery {
    gradient: directusFiles(
      directus_id: { eq: "38fbc5b7-9ae9-4b0a-9c10-a1800a4ea01c" }
    ) {
      id
      cdn
      title
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "7f4669cc-a755-49b8-b8f9-a5c5a67c4f8c"
            "810ee4be-c5dd-4d84-b3a5-13fdbe81f523"
            "ebae1521-5b20-4ec6-afb2-ac64ff81f306"
            "fb926593-84a9-4a59-9666-c6fa633e3035"
            "8841f346-2a38-4169-a671-b4b68ceeb581"
            "64be2cba-5b39-48cb-a2a3-9612d337bb75"
            "d49467d8-e4fa-4398-8a3e-685ecbe55329"
            "83576fd1-d8c7-4b00-8662-1d701096ab6a"
            "d128c156-78e0-4701-99f0-696b618e8ac2"
            "4a6a6bf9-5307-4bc1-bef9-5bf6e723c02f"
            "1a6a9371-7cc4-476a-88c9-4bbbf29f8001"
            "fa68df7c-d22e-4018-96b9-46d2b6b66e22"
            "05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"
            "21dba570-e178-4e0b-ae60-7fb373b554e8"
            "c411faf3-a2bb-4dcd-909b-cc6e24d68a54"
            "38fbc5b7-9ae9-4b0a-9c10-a1800a4ea01c"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
