export const gradientTextAnim = {
  initial: { background: "linear-gradient(90deg, #21264f 0%, #21264f 100%)" },
  active: {
    background: "linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)",
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
}
